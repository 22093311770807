.widget-zone
  border-top 3px double #e9e9e9
  padding-bottom rem(60px)
  .widget
    .widget-title
      font-size rem(18px)
      color dark-color
      font-weight 700
      margin rem(30px) 0

      +tablet()
        text-align center


    .post-grid
      .post-body
        padding-left 0
        padding-right 0

      .post-footer
        margin-left 0
        margin-right 0