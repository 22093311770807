@import '../partials/mixins'
@import '../partials/vars'

.btn
  display inline-block
  line-height rem(50px)
  height rem(50px)
  background-color main-color
  color #fff
  border none
  padding 0 rem(20px)
  border-radius 3px
  box-sizing(border-box)
  transition(all 300ms ease-out)
  text-decoration none
  outline none

  &:hover
    background-color darken(main-color, 10%)

  &.block
    display block
    width 100%

  &.uppercase
    text-transform uppercase

  btn-var('primary', primary-color)