@import '../partials/mixins'
@import '../partials/vars'

.post
  padding rem(65px) 0
  position relative
  border-bottom 3px double light-gray

  +mobile()
    padding rem(20px) 0

  &:last-child
    border-bottom none
    padding-bottom rem(100px)

  img
    max-width 100%

  .post-title
    font-size rem(45px)
    font-weight 100
    margin 0 0 rem(10px)

    +mobile()
      font-size rem(30px)

    a
      color inherit
      text-decoration none

      &:hover
        color primary-color

  .post-thumbnail
    margin rem(30px) 0

  .post-footer
    ks-clearfix()

  a
    color primary-color
    &:hover
      text-decoration none

  p
    margin 0 0 rem(30px)
    font-size rem(15px)
    line-height rem(24px)

  blockquote
    background-color light-gray
    margin-left 10px
    padding rem(28px) rem(30px)
    font-size rem(18px)
    line-height rem(34px)
    font-style italic
    font-weight 200
    position relative
    margin-right 0
    margin-bottom rem(30px)

    +mobile()
      font-size rem(14px)
      margin-right 0
      padding rem(15px)
      line-height rem(24px)

    &:before
      content ""
      position absolute
      top 0
      left -10px
      height 100%
      width 1px
      border-left 1px solid darken(light-gray, 10%)
      border-right 1px solid darken(light-gray, 10%)
      display block

  .read-more
    font-weight 700

  .share
    col(2/5)
    padding-top 3px

    +below(desktop-cutoff)
      strong
        display none

    +mobile()
      stack()

    .share-buttons
      padding 0 0 0 10px
      margin 0
      display inline-block
      vertical-align middle

      li
        a
          color main-color
        &:hover
          a
            color primary-color

  .tags
    col(3/5)
    margin-top rem(-3px)
    text-align right

    +mobile()
      stack()
      margin-top rem(15px)

    .tag
      padding 0 rem(15px)
      line-height rem(34px)
      display inline-block
      vertical-align middle
      color tag-color
      border 1px solid tag-color
      border-radius 3px
      text-decoration none
      margin rem(3px)
      font-size rem(13px)

      &:hover
        color primary-color
        border-color primary-color

  &.post-single
    padding-top rem(65px)
    padding-bottom rem(37px)
    border-bottom 3px double #e8e8e8


.comments-title
  font-size rem(23px)
  font-weight 100
  margin rem(45px) 0 rem(5px)

  strong
    font-weight 500

  +mobile()
    margin-top rem(20px)

.comments
  padding 0
  margin 0
  list-style none

  a
    color primary-color
    font-weight 700
    text-decoration none

    &:hover
      color darken(primary-color, 15%)

  .comment
    .author-image
      display table-cell
      vertical-align top
      padding-right rem(30px)

      +tablet()
        padding-right rem(10px)

      img
        border-radius 3px

        +mobile()
          max-width 40px

        +tablet()
          max-width 60px

    .comment-body
      display table-cell
      width 10000px
      vertical-align top

      p
        margin 0

      .comment-meta
        color dark-color
        margin-bottom rem(10px)

  > .comment
    border-bottom 1px solid comment-bg-color
    padding rem(35px) 0

    +tablet()
      padding rem(15px) 0

    &:last-child
      border-bottom-style double
      border-bottom-width 3px

  .comments
    padding-left rem(68px)

    +mobile()
      padding-left 0

    .comment
      &:last-child
        border none
        padding-bottom 0


.comment-form

  h4
    color dark-color
    font-size rem(18px)
    font-weight 700
    margin rem(30px) 0

  .btn
    text-transform uppercase
    font-weight 700

  .form-half
    col(1/2, cycle: 2, gutter: 1.3)
    margin-bottom rem(13px)

    +mobile()
      stack()
      margin-top 0

  .form-input, .form-textarea
    form-var(#ddd)
    color dark-color
    +placeholder()
      color dark-color
