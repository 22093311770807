section-var(name, bg, color)
  &.{name}

    &:before
      background-image url(bg)

    .section-title
      color color

// Background animation kayframes

.section
  padding-top rem(100px)
  padding-bottom rem(110px)
  overflow hidden

  +between(tablet-cutoff, desktop-cutoff)
    padding-top rem(40px)
    padding-bottom rem(40px)

  +below(tablet-cutoff)
    padding-top rem(40px)
    padding-bottom rem(40px)

  > .container
    z-index 1

    +below(tablet-cutoff)
      padding 0 rem(50px)

    +mobile()
      padding 0 rem(10px)

  .section-title
    margin 0 0 rem(30px) 0
    text-align center
    font-weight 100
    font-size rem(45px)
    color section-title-color

    +tablet()
      font-size rem(30px)

    +mobile()
      font-size rem(24px)
      margin 0 0 rem(15px) 0

    &:after
      content ""
      display block
      width rem(62px)
      border-bottom 1px solid primary-color
      margin rem(30px) auto 0

      +mobile()
        margin-top rem(15px)

    + p
      text-align center
      margin 0 auto rem(50px)
      max-width rem(700px)

  &.bg-variant
    background-color #0f1a24
    color #fff
    position relative

    &:before
      content ""
      background-position top center
      background-repeat no-repeat
      background-size cover
      align(both)
      width 100%
      height 100%
      opacity .2

    &:after
      content: ""
      background-image repeating-linear-gradient( top left, rgba(#000, .27), rgba(#000, .27) 1px, transparent 1px, transparent 100% );
      background-size 6px 6px
      background-repeat repeat
      align(both)
      width 100%
      height 100%

    section-var('one', bg-one, #fff)
    section-var('two', bg-two, #fff)
    section-var('three', bg-three, #fff)
    section-var('four', bg-four, #fff)
    section-var('five', bg-five, #fff)
    section-var('six', bg-six, #fff)

    .form-input, .form-textarea
      form-var(#fff)

    .slick-dots
      li
        button, button:after
          border-color #fff

        &.slick-active, &:hover
          button, button:after
            border-color primary-color


    &.animate
      &:before
        animation bg-move 20s ease-out infinite

  &.bg-gray
    background-color light-gray

  ul
    list-style none
    padding 0
    margin 0

.post-loop
  padding-bottom rem(20px)
  .container
    center(970px)

    +tablet()
      center(700px, 10px)

    +mobile()
      center(100%, 10px)

.blog-footer
  box-shadow(0 6px 0 0 rgba(#fff, .1) inset)

  +below(desktop-cutoff)
    padding-top rem(60px)
    padding-bottom rem(40px)

.subscribe-section
  center(750px)
  padding-bottom 0
  transition(all 1s ease-out)
  position relative
  border-bottom 3px double rgba(#fff, .2)

  &.out
    padding-bottom rem(65px)
    margin-bottom rem(60px)

  +tablet()
    center(700px)

.comments-section
  border-bottom 3px double rgba(#fff, .2)
  padding-bottom rem(65px)

.collapsed
  overflow hidden
  max-height 0
  visibility hidden
  opacity 0
  padding 0

  &.out
    max-height 500px
    visibility visible
    opacity 1

.collapse-zone-control
  position absolute
  top 6px
  left 0
  right 0
  text-align center
  z-index 10

  button
    display inline-block
    background none
    border none
    padding 0 rem(50px)
    line-height rem(37px)
    height rem(37px)
    position relative
    overflow hidden
    border-radius 0
    text-transform uppercase
    outline none

    &:before
      content ""
      position absolute
      top -71px
      left 0
      right 0
      bottom 0
      margin auto
      background-color rgba(#fff, .1)
      width 150px
      height 150px
      zl-rotate(45deg)


