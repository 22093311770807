@import '../partials/mixins'
@import '../partials/vars'

.form-input
  display block
  background none
  border 1px solid main-color
  width 100%
  padding 0 rem(16px)
  outline none
  border-radius 3px
  height rem(50px)
  line-height rem(50px)
  box-sizing(border-box)
  transition(all 250ms ease-out)

  form-var(main-color)

.form-textarea
  display block
  background none
  border 1px solid main-color
  width 100%
  padding rem(18px) rem(20px)
  outline none
  border-radius 3px
  box-sizing(border-box)
  transition(all 250ms ease-out)

  form-var(main-color)

.form-group-inline
  display table
  width 100%

  > .btns
    padding-right rem(15px)
    width 1%

    +below(tablet-cutoff)
      display block
      width auto
      padding-right 0
      margin-top rem(15px)

  > .form-input, > .form-textarea, > .btns
    display table-cell

    +below(tablet-cutoff)
      display block

  > .form-input, > .form-textarea
    width 100%
    position relative

    + .btns
      padding-left rem(15px)
      padding-right 0

      +below(tablet-cutoff)
        padding-left 0

        .btn
          display block
          width 100%

.form-group
  margin-bottom rem(26px)

  &:last-child
    margin-bottom 0

.form-half
  col(1/2, cycle: 2, gutter: 3)

  +mobile()
    stack()
    + .form-half
      margin-top rem(26px)
